<template>
  <div>
    <div class="form-group">
      <div class="input-wrapper">
        <input-field
          :label="$t('enterIIN')"
          field-type="tel"
          :is-block="true"
          v-model="iin"
          name="iin"
          mask="############"
        />
      </div>
    </div>
    <div class="p-3" v-if="ogpoPersonInfo">
      <div class="primary-block">
        <p class="secondary-text">{{$t('driverName')}}</p>
        <p class="primary-text">{{ ogpoPersonInfo.fullName }}</p>
      </div>
    </div>
    <button
      class="primary-btn bottom-page-btn"
      @click="addPerson"
      :disabled="!ogpoPersonInfo"
    >
      Сохранить
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import InputField from "../BuyPolicy/InputField";

export default {
  name: "GetInfoPerson",
  components: { InputField },
  data() {
    return {
      iin: "",
    };
  },
  computed: {
    ...mapGetters(["ogpoPersonInfo", "policyForm"]),
  },
  methods: {
    ...mapActions([
      "getOgpoPersonInfo",
      "addPersonToPolicyForm",
      "getBonuses",
      "getCascoLite",
    ]),
    ...mapMutations(["CLEAR_OGPO_PERSON_INFO"]),
    async addPerson() {
      await this.addPersonToPolicyForm(this.ogpoPersonInfo);
      if (
        this.policyForm.cars.length > 0 &&
        this.policyForm.persons.length > 0
      ) {
        this.getCascoLite();
        this.getBonuses();
      }

      this.$router.go(-1);
    },
  },
  watch: {
    iin(value) {
      if (value.length === 12) {
        this.getOgpoPersonInfo(value);
      }
    },
  },
  beforeDestroy() {
    this.CLEAR_OGPO_PERSON_INFO();
  },
};
</script>

<style scoped>
</style>
