<template>
  <div>
    <div class="form-group">
      <div class="input-wrapper">
        <input-field
          :label="$t('addCarNumber')"
          field-type="text"
          :is-block="true"
          v-model="carNumber"
          name="carNumber"
          mask="XXXXXXXX"
        />
      </div>
    </div>
    <div class="p-3" v-if="ogpoCarInfo">
      <div class="primary-block">
        <p class="secondary-text">{{$t('carModel')}}</p>
        <p v-if="ogpoCarInfo.mark && ogpoCarInfo.model" class="primary-text">
          {{ ogpoCarInfo.mark }} {{ ogpoCarInfo.model }}
        </p>
        <p v-if="ogpoCarInfo.value" class="primary-text danger-text">
          {{ ogpoCarInfo.value }}
        </p>
      </div>
    </div>

    <button
      class="primary-btn bottom-page-btn"
      @click="addCar"
      :disabled="!ogpoCarInfo"
    >
      {{$t('save')}}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import InputField from "../BuyPolicy/InputField";

export default {
  name: "GetInfoCar",
  components: {
    InputField,
  },
  data() {
    return {
      carNumber: "",
    };
  },
  computed: {
    ...mapGetters(["ogpoCarInfo", "policyForm"]),
  },
  methods: {
    ...mapActions([
      "getOgpoCarInfo",
      "addCarToPolicyForm",
      "getBonuses",
      "getCascoLite",
    ]),
    ...mapMutations(["CLEAR_OGPO_CAR_INFO"]),
    async addCar() {
      await this.addCarToPolicyForm(this.ogpoCarInfo);
      if (
        this.policyForm.cars.length > 0 &&
        this.policyForm.persons.length > 0
      ) {
        this.getCascoLite();
        this.getBonuses();
      }
      this.$router.go(-1);
    },
  },
  watch: {
    carNumber(value) {
      if (value.length === 7 && !Number.isInteger(parseInt(value[0]))) {
        this.getOgpoCarInfo(value);
      } else if (value.length === 8) {
        this.getOgpoCarInfo(value);
      }
    },
  },
  beforeDestroy() {
    this.CLEAR_OGPO_CAR_INFO();
  },
};
</script>

<style scoped>
</style>
