<template>
  <secondary-page-layout :title="product.title">
    <component :is="componentName"></component>
  </secondary-page-layout>
</template>

<script>
import GetInfoPerson from "../components/GetInfo/GetInfoPerson";
import GetInfoCar from "../components/GetInfo/GetInfoCar";

export default {
  name: "GetInfo",
  components: {
    GetInfoPerson,
    GetInfoCar
  },
  data() {
    return {
      info: null,
      product: {},
    }
  },
  computed: {
    componentName() {
      switch (this.$route.params.typeOfInput) {
        case 'car':
          return 'get-info-car';
        case 'person':
          return 'get-info-person';
        default:
          return '';
      }
    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      try {
        const {data} = await window.axios.get(`insurance-products/${this.$route.params.id}`);
        this.product = data;
      } catch (e) {
        throw e;
      }
    }
  }
}
</script>

<style scoped>
input {
  padding: 10px 0;
}
</style>
